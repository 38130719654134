<template>
  <div class="person-info">
    <van-nav-bar title="个人信息" left-arrow @click-left="onClickLeft" />

    <div class="main">
      <div class="info-item">
        <span class="tit">头像</span>
        <van-uploader :after-read="afterRead">
           <!-- :before-read="beforeRead" -->
           <!-- :capture="shot" -->
          <div class="content">
            <img :src="profile.headImg" v-if="profile.headImg" alt="" />
            <img src="~@image/headportrait.png" v-else alt="" />
            <div class="edit"><img src="~@image/pi-edit.png" alt="" /></div>
          </div>
        </van-uploader>
      </div>

      <div class="info-item">
        <span class="tit">名称</span>

        <div class="content">
          <router-link to="modifynickname">
            {{ profile.nickName ? profile.nickName : profile.mobile }}
          </router-link>
        </div>
      </div>

      <div class="info-item">
        <span class="tit">手机号</span>

        <div class="content">
          <router-link to="modifyphone">{{ profile.mobile }}</router-link>
        </div>
      </div>
      <!-- <div class="info-item">

                <span class="tit">地区</span>

                <div class="content">广东清远</div>

            </div>

            <div class="info-item">

                <span class="tit">个性签名</span>

                <div class="content">
                    <router-link to="modifypersign">{{$store.state.profile.scontent}}</router-link>
                </div>

            </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { uploadHeadPortRait } from "@api/user.js";

export default {
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //上传头像
    afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      uploadHeadPortRait(param).then((res) => {
        if (res.code == 0) {
          this.$store.dispatch("GET_PROFILE");
          this.$toast("修改成功");
        } else {
          this.$toast(res.msg);
        }
      });
  },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #333;
}

::v-deep .van-nav-bar__title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}

.person-info {
  font-family: PingFang SC;
  .choose-md{
    padding:10px 15px;
    display:flex;
    flex-direction:column;
    div:first-child~div{
      margin-top:10px;
    }
  }
  .main {
    padding: 14.5px 30px 0;
    border-top: 11px solid #f4f4f4;

    .info-item:first-child {
      height: 51px;
      border-bottom: none;

      ~ .info-item {
        .content {
          position: relative;
        }

        .content::before {
          content: "";
          position: absolute;
          display: block;
          width: 6px;
          height: 11px;
          right: 0;
          top: calc(50% - 4.5px);
          background: url("~@image/pi-arrow-r.png") center no-repeat;
          background-size: 6px 11px;
        }
      }

      .tit {
        width: 249.5px;
        height: 100%;
        line-height: 51px;
        border-bottom: 1px solid #eee;
      }

      .content {
        position: relative;
        width: 51px;
        height: 51px;
        padding: 0;
        > img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
        .edit {
          position: absolute;
          width: 12px;
          height: 12px;
          right: 2px;
          bottom: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 53px;
      border-bottom: 1px solid #eee;
      .tit {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }

      .content {
        max-width:230px;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        padding-right: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;

        a {
          color: #333333;
        }
      }
    }

    .info-item:last-child {
      .content {
        width: 200px;
        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>